import { Script } from "gatsby";
import React from "react";
import { useSiteMetadata } from "../services/use-site-metadata";

export interface SeoProps {}

const Seo: React.FC<SeoProps> = ({}) => {
  const { title, description, image, siteUrl, keywords } = useSiteMetadata();
  return (
    <>
      <script
        type="text/plain"
        cookie-consent="tracking"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-KEZTRHMK6Y"
      ></script>
      <script type="text/plain" cookie-consent="tracking">
        {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
    
                gtag('config', 'G-KEZTRHMK6Y', { 'anonymize_ip': true });
                `}
      </script>
      <Script
        src="https://www.privacypolicies.com/public/cookie-consent/4.0.0/cookie-consent.js"
        onLoad={() => {
          const win = window as any;
          if (win && win.cookieconsent) {
            win.cookieconsent.run({
              notice_banner_type: "simple",
              consent_type: "express",
              palette: "light",
              language: "en",
              page_load_consent_levels: ["strictly-necessary"],
              notice_banner_reject_button_hide: true,
              preferences_center_close_button_hide: false,
              page_refresh_confirmation_buttons: false,
              website_name: "ComplyCheck",
              website_privacy_policy_url: "https://complycheck.ch/privacy",
            });
          }
        }}
      ></Script>
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta name="robots" content="all" />

      <meta property="og:title" content="ComplyCheck.ch" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content="ComplyCheck.ch Logo" />
      <meta property="og:image:type" content="png" />

      <meta property="twitter:card" content="Summary" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:site" content={siteUrl} />
      <meta property="twitter:image" content={image} />

      <link
        rel="icon"
        href="https://assets.complycheck.ch/static/favicon-32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        href="https://assets.complycheck.ch/static/favicon-128.png"
        sizes="128x128"
      />
      <link
        rel="icon"
        href="https://assets.complycheck.ch/static/favicon-192.png"
        sizes="192x192"
      />

      <link
        rel="apple-touch-icon"
        href="https://assets.complycheck.ch/static/apple-touch-icon.png"
        sizes="180x180"
      />
    </>
  );
};

export default Seo;
