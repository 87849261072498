import classNames from "classnames";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import logo from "../images/logos/cc-logo-white.svg";
import IconContact from "../images/icons/contact_us.svg";
import { ActivePageModel } from "../models/active-page.model";
import linksService from "../services/links.service";
export interface FooterProps {
  activePage: ActivePageModel;
  footer: Queries.FooterFragment | null;
}

const linkClass = "text-white text-sm underline hover:text-confirm";
const Footer: React.FC<FooterProps> = ({ activePage, footer }) => {
  const data = footer?.prismicFooter?.data;
  return (
    <footer>
      <div className="bg-primary-700">
        <div className="container mx-auto flex flex-col items-center py-8 lg:flex-row">
          <img className="w-44" src={logo} alt="Comply check logo" />
          <div className="text-white text-sm lg:ml-4">
            © {data?.all_rights_reserved}
          </div>
          <a
            href="/privacy"
            target="_blank"
            className={classNames(linkClass, "md:ml-4")}
          >
            {data?.privacy_policy}
          </a>
          <a
            href="/terms"
            target="_blank"
            className={classNames(linkClass, "md:ml-4")}
          >
            {data?.terms}
          </a>
          <Link to={`/impressum`} className={classNames(linkClass, "md:ml-4")}>
            {data?.impressum}
          </Link>

          <div className="flex flex-col items-center lg:flex-row gap-4 mt-4 lg:mt-0 lg:gap-8 lg:ml-auto group">
            <a href={linksService.getContactUsAddress()} className={linkClass}>
              <IconContact className="w-5 h-5 inline-block mr-2 fill-white group-hover:fill-confirm" />
              {data?.contact_us}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export const query = graphql`
  fragment Footer on Query {
    prismicFooter(lang: { eq: $lang }) {
      data {
        contact_us
        privacy_policy
        terms
        impressum
        all_rights_reserved
      }
    }
  }
`;

export default Footer;
