import { PrismicRichText } from "@prismicio/react";
import React from "react";

export interface HtmlTextProps {
  text: any;
}

const HtmlText: React.FC<HtmlTextProps> = ({ text }) => {
  return (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => (
          <h1 className="text-primary text-3xl font-medium">{children}</h1>
        ),
        heading2: ({ children }) => (
          <h2 className="text-black text-2xl">{children}</h2>
        ),
        heading3: ({ children }) => (
          <h3 className="text-black text-xl">{children}</h3>
        ),
        heading4: ({ children }) => (
          <h4 className="text-black text-base">{children}</h4>
        ),
        heading5: ({ children }) => (
          <h5 className="text-black text-base">{children}</h5>
        ),
        paragraph: ({ children }) => (
          <p className="text-black py-4">{children}</p>
        ),
        list: ({ children }) => <ol className="list-disc px-4">{children}</ol>,
        oList: ({ children }) => (
          <ol className="list-decimal px-4">{children}</ol>
        ),
      }}
    />
  );
};

export default HtmlText;
