class LinksService {
  baseUrl = process.env.GATSBY_APP_BASE_URL;

  getLoginUrl() {
    const loginUrl = `${this.baseUrl}/auth/login`;
    return loginUrl;
  }
  getSignUpUrl() {
    const signUpUrl = `${this.baseUrl}/auth/register`;
    return signUpUrl;
  }
  getContactUsAddress() {
    return "mailto:contact@complycheck.ch";
  }
  getSubscriptionsUrl() {
    const subscriptionUrl = `${this.baseUrl}/subscription`;
    return subscriptionUrl;
  }

  getMercuryUrl() {
    return "https://mercury.ch";
  }

  getSigoorUrl() {
    return "https://sigoor.com";
  }
}

const linksService = new LinksService();
export default linksService;
