import { graphql, HeadFC, PageProps } from "gatsby";
import React from "react";
import Footer from "../components/footer";
import HtmlText from "../components/htmlText";
import Seo from "../components/seo";

const Privacy: React.FC<PageProps<Queries.PrivacyPolicyQuery>> = ({ data }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="container mx-auto py-16">
        <HtmlText
          text={data.prismicPrivacy?.data.privacy_policy_text?.richText}
        />
      </main>
      <Footer footer={data} activePage={data.prismicPrivacy!} />
    </div>
  );
};

export const query = graphql`
  query PrivacyPolicy($lang: String) {
    ...Footer
    ...Global

    prismicPrivacy(lang: { eq: $lang }) {
      id
      lang
      type
      url
      data {
        privacy_policy_text {
          richText
        }
      }
    }
  }
`;

export default Privacy;

export const Head: HeadFC = () => <Seo />;
